import { axiosInternalInstance } from 'app/interceptors';

export const getAnnualPlanDashboard = async (params, signal) => {
  const {data} = await axiosInternalInstance.get(`/v1/dashboard/annual_plan`, { params, signal });
  return data;
}

export const getRecommendationDashboard = async (params, signal) => {
  const {data} = await axiosInternalInstance.get(`/v1/dashboard/internal_recommendation`, { params, signal });
  return data;
}

export const getExternalDashboard = async (params, signal) => {
  const {data} = await axiosInternalInstance.get(`/v1/dashboard/external_audit`, { params, signal });
  return data;
}

export const getInternalDashboard = async (params, signal) => {
  const {data} = await axiosInternalInstance.get(`/v1/dashboard/internal_audit`, { params, signal });
  return data;
}

