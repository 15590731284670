import { axiosInternalInstance } from "app/interceptors";


export const getExternalAudit = async (params, signal) => {
  const {data} = await axiosInternalInstance.get(`/v1/external`, { params, signal });
  return data;
}

export const getOneExternalAudit = async (id, signal) => {
  const {data} = await axiosInternalInstance.get(`/v1/external/${id}`, { signal });
  return data;
}

export const createNewExternalAudit = async (payload) => {
  const {data} = await axiosInternalInstance.post(`/v1/external`, payload);
  return data;
}

export const updateExternalAudit = async (id, payload) => {
  const {data} = await axiosInternalInstance.patch(`/v1/external/${id}`, payload);
  return data;
}

export const getExternalRecommendations = async (params, signal) => {
  const {data} = await axiosInternalInstance.get(`/v1/external_recommendation`, { params, signal });
  return data;
}

export const getExternalRecommendationById = async (id, signal) => {
  const {data} = await axiosInternalInstance.get(`/v1/external_recommendation/${id}`, { signal });
  return data;
}

export const getExternalRecommendationSummary = async (params, signal) => {
  const {data} = await axiosInternalInstance.get(`/v1/external_recommendation/summary`, { params, signal });
  return data;
}

export const updateStatus = async (id, payload, signal) => {
  const {data} = await axiosInternalInstance.post(`/v1/external_recommendation/${id}/approval`, payload, { signal });
  return data;
}

export const updateRecommendation = async (id, payload, signal) => {
  const {data} = await axiosInternalInstance.post(`/v1/external_recommendation/${id}/follow_up`, payload, { signal });
  return data;
}