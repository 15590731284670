import classNames from "classnames";
import React from "react";

const RecommendationCard = ({ type, value, isActive, onClickCard }) => {
  let typeText = "Total Rekomendasi";
  let defaultClass =
    "p-6 rounded-xl bg-white shadow-sm border-l-4 border-l-primary-700 cursor-pointer space-y-2 flex-1";
  switch (type) {
    case "total_recommendation_finished":
      typeText = "Total Rekomendasi Tuntas";
      break;
    case "total_recommendation_not_finished":
      typeText = "Total Rekomendasi Tidak Tuntas";
      break;
    case "total_recommendation_incomplete":
      typeText = "Total Rekomendasi Belum Dikerjakan";
      break;
    default:
      break;
  }
  return (
    <div
      className={classNames([...defaultClass.split(" ")], {
        "!bg-primary-25": isActive,
      })}
      onClick={() => onClickCard(type)}
    >
      <div className="text-sm text-gray-600">{typeText}</div>
      <div className="text-md font-bold">{value}</div>
    </div>
  );
};

export default RecommendationCard;
