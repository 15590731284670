import React from "react";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement } from "chart.js";
import { Pie } from "react-chartjs-2";
import Spinner from "components/atoms/Spinner";
import { isEmpty } from "lodash";

ChartJS.register(ArcElement, Title, Tooltip, Legend, ChartDataLabels);

const PieChartComponent = ({
  data = {},
  options = {},
  isLoading,
  ...props
}) => {
  return !isLoading && !isEmpty(data) ? (
    <Pie data={data} options={options} {...props} />
  ) : (
    <div className="relative min-h-[200px]">
      <Spinner />
    </div>
  );
};

export default PieChartComponent;
