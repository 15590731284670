import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import Spinner from "components/atoms/Spinner";
import { isEmpty } from "lodash";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BarChartComponent = ({ data = {}, options = {}, isLoading }) => {
  return !isLoading && !isEmpty(data) ? (
    <Bar data={data} options={options} />
  ) : (
    <div className="relative min-h-[200px]">
      <Spinner />
    </div>
  );
};

export default BarChartComponent;
